import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { PrismicRichText } from "@prismicio/react"
import { getImage } from "gatsby-plugin-image"
import styled from 'styled-components'

import PageHeaderImageBgNew from '../../components/headers/PageHeaderImageBgNew'
import OneColumnPageLayout from "../../components/layouts/OneColumnPageLayout"
import YoutubePlayerLite from "../../components/common/YoutubePlayerLite"

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const AboutSection = () => {
  const data = useStaticQuery(graphql`
    query AboutSectionQuery {
      prismicAboutPage {
        data {
          page_title
          page_description
          page_hero_background {
            gatsbyImageData(width: 1024)
          }
          about_content {
            richText
          }
          about_youtube_video_id
        }
      }
    }
  `)

  const doc = data.prismicAboutPage.data
  const imageBg = getImage(doc.page_hero_background)

  return (
    <>
      <PageHeaderImageBgNew imageData={imageBg}>
        <StyledDiv className='w-11/12 xl:max-w-screen-xl flex flex-col items-center'>
          <h1 className='font-display font-extrabold text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight mb-6'>
            {doc.page_title}
          </h1>

          <h2 className='md:w-8/12 font-body text-xl text-red-800 font-semibold mb-4 text-center'>
            {doc.page_description}
          </h2>
        </StyledDiv>
      </PageHeaderImageBgNew>

      <OneColumnPageLayout>
        <div className='w-full float-none md:float-right mb-6 md:m-6 md:w-1/2 shadow-xl'>
          <YoutubePlayerLite youtubeID={doc.about_youtube_video_id} />
        </div>

        <PrismicRichText
          field={doc.about_content.richText}
          components={{
            paragraph: ({ children }) => <p className="prismic-p-class">{children}</p>,
            list: ({ children }) => <ul className="prismic-ul-class">{children}</ul>,
            oList: ({ children }) => <ol className="prismic-ol-class">{children}</ol>,
            listItem: ({ children }) => <li className="prismic-li-class">{children}</li>,
            oListItem: ({ children }) => <li className="prismic-li-class">{children}</li>
          }}
        />
      </OneColumnPageLayout>
    </>
  )
}

export default AboutSection