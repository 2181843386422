import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import MainContainer from "../components/containers/MainContainer"
import HeadDataNew from "../data/HeadDataNew"
import AboutSection from "../components/sections/AboutSection"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query AboutPageQuery {
      prismicAboutPage {
        data {
          meta_title
          meta_description
        }
      }
    }
  `)

  const doc = data.prismicAboutPage.data

  return (
    <MainContainer>
      <HeadDataNew
        title={doc.meta_title}
        description={doc.meta_description}
      />

      <AboutSection />
    </MainContainer>
  )
}

export default AboutPage